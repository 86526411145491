import React from 'react';
import "./KontaktFormSuccess.css";

function KontaktFormSuccess() {
    return (
        <div className="kontakt_success">
            {/* WebM-Video einbetten */}
            <video className="kontakt_success_video" autoPlay loop muted>
                <source src="./img/check.webm" type="video/webm" />
                Ihr Browser unterstützt das Video-Format nicht.
            </video>

            <div className="kontakt_success_text">
                <h3>Vielen Dank für Ihre Anfrage!</h3>
                <p>Wir melden uns in Kürze bei Ihnen!</p>
            </div>

            <div className="kontakt_success_img">
                <img src="./img/Peter_Weber.jpg" alt="Inge Nopp" />
            </div>
        </div>
    );
}

export default KontaktFormSuccess;
