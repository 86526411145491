import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import "./PopularProductAnalytics.css";

// Chart.js Komponenten registrieren
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function PopularProductAnalytics({ entries }) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const productCounts = {};

    // Produkte zählen
    entries.forEach((entry) => {
      const product = entry.produkt || "Unbekannt";
      productCounts[product] = (productCounts[product] || 0) + 1;
    });

    // Daten für das Balkendiagramm vorbereiten
    const labels = Object.keys(productCounts);
    const data = Object.values(productCounts);

    setChartData({
      labels,
      datasets: [
        {
          label: "Anfragen pro Produkt",
          data,
          backgroundColor: labels.map(
            (_, index) =>
              `hsl(${(index * 360) / labels.length}, 70%, 60%)` // Farbschema generieren
          ),
          borderColor: "#ffffff",
          borderWidth: 1,
        },
      ],
    });
  }, [entries]);

  if (!chartData) return <div>Laden...</div>;

  return (
    <div className="popular-product-analytics">
      <h2>Beliebteste Produkte</h2>
      <div className="chart-container-2">
        <Bar data={chartData} options={{ responsive: true }} />
      </div>
    </div>
  );
}

export default PopularProductAnalytics;
