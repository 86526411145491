import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import "./MonthAnalytics.css";

// Chart.js Komponenten registrieren
ChartJS.register(ArcElement, Tooltip, Legend);

function MonthAnalytics({ entries }) {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const monthCounts = Array(12).fill(0); // Array für 12 Monate initialisieren

    // Zähle die Anfragen pro Monat
    entries.forEach((entry) => {
      const entryDate = new Date(entry.timestamp || 0); // Sicherstellen, dass der timestamp existiert
      if (entryDate.getFullYear() === currentYear) {
        const month = entryDate.getMonth(); // Monat von 0 (Januar) bis 11 (Dezember)
        monthCounts[month] += 1;
      }
    });

    // Nicht wiederholende Farben für jeden Monat
    const backgroundColors = [
        "#FF0000", // Januar - Rot
        "#FF7F00", // Februar - Orange
        "#FFFF00", // März - Gelb
        "#7FFF00", // April - Hellgrün
        "#00FF00", // Mai - Grün
        "#00FF7F", // Juni - Türkis
        "#00FFFF", // Juli - Cyan
        "#007FFF", // August - Blau
        "#0000FF", // September - Dunkelblau
        "#7F00FF", // Oktober - Violett
        "#8B00FF", // November - Dunkelviolett
        "#FF00FF", // Dezember - Magenta
      ];
    // Tortendiagramm-Daten erstellen
    setChartData({
      labels: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      datasets: [
        {
          label: "Anfragen pro Monat",
          data: monthCounts,
          backgroundColor: backgroundColors,
          borderColor: "#ffffff",
          borderWidth: 1,
        },
      ],
    });
  }, [entries]);

  if (!chartData) return <div>Laden...</div>;

  return (
    <div className="month-analytics">
      <h2>Anfragen pro Monat</h2>
      <div className="chart-container">
        <Pie data={chartData} />
      </div>
    </div>
  );
}

export default MonthAnalytics;
