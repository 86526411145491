import React from "react";
import ReactDOM from "react-dom/client"; // Verwende React 18 'react-dom/client'
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// Rendere die App
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Performance-Tracking (optional)
reportWebVitals();
