import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "./Login.css";

function Login({ setAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setAuthenticated(true); // Authentifizierung erfolgreich
      setError("");
      localStorage.setItem("authenticated", "true"); // Authentifizierungsstatus speichern
    } catch (err) {
      console.error("Login-Fehler:", err.code);
      if (err.code === "auth/user-not-found") {
        setError("Benutzer existiert nicht.");
      } else if (err.code === "auth/wrong-password") {
        setError("Falsches Passwort.");
      } else {
        setError("Anmeldefehler. Bitte versuchen Sie es erneut.");
      }
    }
  };

  useEffect(() => {
    const authStatus = localStorage.getItem("authenticated");
    if (authStatus === "true") {
      setAuthenticated(true);
    }
  }, [setAuthenticated]);

  return (
    <div className="login-container">
      <form onSubmit={handleLogin} className="login-form">
        <h1>Login</h1>
        {error && <p className="login-error">{error}</p>}
        <input
          type="email"
          placeholder="E-Mail-Adresse"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Anmelden</button>
      </form>
    </div>
  );
}

export default Login;
