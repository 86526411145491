import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./Home.js";
import Header from "./Header.js";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Footer from "./Footer/Footer.js";
import Produkt from "./Produkte/Produkt.js";
import Ueber from "./UeberUns/Ueber.js";
import Kontakt from "./Kontakt/Kontakt.js";
import Luxuskartonagen from "./Produkte/Luxuskartonagen";
import Diplomrollen from "./Produkte/Diplomrollen";
import Rohkartons from "./Produkte/Rohkartons";
import Schulschachteln from "./Produkte/Schulschachteln";
import Stanzprodukte from "./Produkte/Stanzprodukte";
import Spulen from "./Produkte/Spulen";
import Faltkartons from "./Produkte/Faltkartons";
import Aktenkartons from "./Produkte/Aktenkartons";
import Klarsichtpackungen from "./Produkte/Klarsichtpackungen";
import Ueberzogene from "./Produkte/Ueberzogene";
import Teebäckerei from "./Produkte/Teebäckerei";
import ScrollToTop from "./ScrollToTop";
import TopPic from "./TopPic";
import Agb from "./Agb";
import TestDatabase from "./TestDatabase";
import BackendAnalytics from "./Backend/BackendAnalytics";
import Login from "./Backend/Login";

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  // Authentifizierungsstatus aus localStorage abrufen
  useEffect(() => {
    const authStatus = localStorage.getItem("authenticated");
    if (authStatus === "true") {
      setAuthenticated(true);
    }
  }, []);

  // Authentifizierungsstatus in localStorage speichern
  const handleAuthentication = (status) => {
    setAuthenticated(status);
    if (status) {
      localStorage.setItem("authenticated", "true");
    } else {
      localStorage.removeItem("authenticated");
    }
  };

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Switch>
          {/* Geschützte Route */}
          <Route path="/analytics">
            {authenticated ? (
              <>
                <Header />
                <BackendAnalytics />
                <Footer />
              </>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          {/* Login Route */}
          <Route path="/login">
            {authenticated ? (
              <Redirect to="/analytics" />
            ) : (
              <Login setAuthenticated={handleAuthentication} />
            )}
          </Route>

          {/* Öffentlich zugängliche Routen */}
          <Route path="/produkt">
            <Header />
            <Produkt />
          </Route>
          <Route path="/Luxuskartonagen">
            <Header />
            <Luxuskartonagen />
            <Footer />
          </Route>
          <Route path="/Aktenkartons">
            <Header />
            <Aktenkartons />
            <Footer />
          </Route>
          <Route path="/Konfekt-Kartons">
            <Header />
            <Teebäckerei />
            <Footer />
          </Route>
          <Route path="/Überzogene-Kartonagen">
            <Header />
            <Ueberzogene />
            <Footer />
          </Route>
          <Route path="/Klarsichtpackungen">
            <Header />
            <Klarsichtpackungen />
            <Footer />
          </Route>
          <Route path="/Schulschachteln">
            <Header />
            <Schulschachteln />
            <Footer />
          </Route>
          <Route path="/Faltkarton">
            <Header />
            <Faltkartons />
            <Footer />
          </Route>
          <Route path="/Spulen">
            <Header />
            <Spulen />
            <Footer />
          </Route>
          <Route path="/Stanzprodukte">
            <Header />
            <Stanzprodukte />
            <Footer />
          </Route>
          <Route path="/Rohe-Kartonagen">
            <Header />
            <Rohkartons />
            <Footer />
          </Route>
          <Route path="/Diplomrollen">
            <Header />
            <Diplomrollen />
            <Footer />
          </Route>
          <Route path="/impressum">
            <Header />
            <Agb />
            <Footer />
          </Route>
          <Route path="/über">
            <Header />
            <Ueber />
            <Footer />
          </Route>
          <Route path="/kontakt">
            <Header />
            <TopPic />
            <Kontakt />
            <Footer />
          </Route>
          <Route path="/test">
            <Header />
            <TestDatabase />
            <Footer />
          </Route>
          <Route path="/">
            <Header />
            <Home />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
