import React, { useState } from "react";
import "./ProduktKontakt.css";
import KontaktForm from "./KontaktForm.js";
import KontaktFormSuccess from "./KontaktFormSuccess";

function ProduktKontakt({ Produkt }) {
  const [Submitted, setSubmitted] = useState(false);

  function submitForm() {
    setSubmitted(true);
  }

  return (
    <div className="kontakt-1">
      <div className={!Submitted ? "produkt_kontakt" : "produkt_hidden"}>
        <div className="produkt_kontakt_head">
          <h4>Produktanfrage</h4>
          <img src="./img/typewriter-2.svg" alt="kontakt symbol" />
        </div>
        <p>
          Hier können Sie eine unverbindliche Produktanfrage versenden. Unser
          Team meldet sich daraufhin umgehend bei Ihnen.
        </p>
      </div>
      {!Submitted ? (
        <KontaktForm submitForm={submitForm} ProduktName={Produkt} />
      ) : (
        <KontaktFormSuccess />
      )}
    </div>
  );
}

export default ProduktKontakt;
