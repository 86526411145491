import React, { useState } from 'react';
import './ProduktKontakt.css';
import KontaktForm3 from "./KontaktForm_3.js";
import KontaktFormSuccess from './KontaktFormSuccess';




function ProduktKontakt_3() {
    const [Submitted, setSubmitted] = useState(false)

function submitForm() {
  setSubmitted(true);
}

  return (
    <div className="kontakt_alternative">
        <div className= {!Submitted? "produkt_kontakt" : "produkt_hidden"}>

            <h4>Jetzt Anfragen</h4>
            <p>Hier können Sie Schulschachteln direkt anfragen. Geben Sie dazu bitte alle Informationen ein.</p>
        </div>
       {!Submitted ? <KontaktForm3 submitForm={submitForm} /> : <KontaktFormSuccess /> }
       </div>
  );
}

export default ProduktKontakt_3;
