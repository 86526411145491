import React, { useState } from "react";
import "./ProduktKontakt.css";
import { database } from "../firebaseConfig";
import { ref, push } from "firebase/database";
import { storage } from "../firebaseConfig";
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";

function KontaktForm({ submitForm, ProduktName }) {
  const [Submitted, setSubmitted] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Für den Spinner

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("Die Datei ist zu groß. Bitte wählen Sie eine Datei mit maximal 5 MB.");
        return;
      }
      if (file.type === "image/jpeg" || file.type === "image/png") {
        setSelectedImage(file);
        setImagePreview(URL.createObjectURL(file));
      } else {
        alert("Bitte nur JPG oder PNG Dateien auswählen.");
      }
    }
  };

  const sendRequest = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Spinner aktivieren

    const formData = new FormData(e.target);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      telefon: formData.get("telefon"),
      message: formData.get("message"),
      produkt: formData.get("produkt"),
      stueckzahl: formData.get("stkzahl"),
      timestamp: new Date().toISOString(),
    };

    let imageUrl = null;
    if (selectedImage) {
      const imageStorageRef = storageRef(
        storage,
        `anfragen-bilder/${selectedImage.name}`
      );
      try {
        const snapshot = await uploadBytes(imageStorageRef, selectedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
        data.imageUrl = imageUrl;
      } catch (error) {
        console.error("Fehler beim Hochladen des Bildes:", error);
        alert("Es gab einen Fehler beim Hochladen des Bildes. Bitte versuchen Sie es erneut.");
        setIsSubmitting(false); // Spinner deaktivieren
        return;
      }
    }

    const dbRef = ref(database, "/Anfragen");
    push(dbRef, data)
      .then(() => {
        e.target.reset();
        setSelectedImage(null);
        setImagePreview(null);
        setSubmitted(true);
        submitForm();
      })
      .catch((error) => {
        console.error("Fehler beim Speichern der Anfrage:", error);
        alert("Es gab einen Fehler beim Senden der Anfrage. Bitte versuchen Sie es später erneut.");
      })
      .finally(() => {
        setIsSubmitting(false); // Spinner deaktivieren
      });
  };

  const removeImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
  };

  return (
    <div className="formular">
      <form onSubmit={sendRequest} id="contact-form" name="contact-form">
        <input
          className="input"
          placeholder="Vor & Nachname*"
          name="name"
          type="text"
          required
        />
        <input
          className="input"
          placeholder="E-Mail-Adresse*"
          type="email"
          name="email"
          required
        />
        <input
          className="input"
          placeholder="Telefonnummer"
          name="telefon"
          type="text"
        />

        <select className="input3" name="stkzahl" required>
          <option value="1-10">1-10 Stück</option>
          <option value="11-100">11-100 Stück</option>
          <option value="101-200">101-200 Stück</option>
          <option value="201-500">201-500 Stück</option>
          <option value="500+">500+ Stück</option>
        </select>

        <textarea
          className="input5"
          placeholder="Wie können wir Ihnen weiterhelfen?"
          type="text"
          name="message"
        />
        <select
          className="input_6"
          name="produkt"
          required
          defaultValue={ProduktName}
        >
          <option value={ProduktName}>{ProduktName}</option>
        </select>

        <div
          className="image-upload-container"
          onClick={() => document.getElementById("image").click()}
        >
          {imagePreview ? (
            <span>
              <img
                src={imagePreview}
                alt="Bildvorschau"
                className="image-preview"
              />
              <button
                type="button"
                className="remove-image-button"
                onClick={(e) => {
                  e.stopPropagation();
                  removeImage();
                }}
              >
                ✕
              </button>
            </span>
          ) : (
            <div className="image-upload-container-wrapper">
              <img src="./img/img-upload.svg" alt="" />
              <span>Bild hochladen (.jpeg / .png)</span>
            </div>
          )}
        </div>
        <input
          type="file"
          id="image"
          className="hidden-image-input"
          accept="image/jpeg, image/png"
          onChange={handleImageChange}
        />

        <div className="checkbox_container">
          <input type="checkbox" id="kontakt" name="kontakt" required />
          <label htmlFor="kontakt">
            Ich akzeptiere die Datenschutzbestimmungen
          </label>
        </div>
        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {isSubmitting ? (
            <span className="spinner"></span> // Spinner anzeigen
          ) : (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p>Absenden</p>
            <img style={{filter: 'invert(1)', width: '18px', marginLeft: '5px'}} src="./img/right.svg" alt="" />
            </div>
          )}
        </button>
      </form>
    </div>
  );
}

export default KontaktForm;
