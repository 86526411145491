// Importiere die benötigten Funktionen
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; // Storage importieren

// Deine Firebase-Konfiguration
const firebaseConfig = {
  apiKey: "AIzaSyDZvMjfdT3792EQ7k_831tqXBfLw6LvB0U",
  authDomain: "weber-neu.firebaseapp.com",
  databaseURL: "https://weber-neu-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "weber-neu",
  storageBucket: "weber-neu.firebasestorage.app", // <-- Das ist der korrekte Bucket-Name
  messagingSenderId: "567059008754",
  appId: "1:567059008754:web:30cc2af55d1b6a4fc5d46b",
};

// Initialisiere Firebase
const app = initializeApp(firebaseConfig);

// Initialisiere Realtime Database
export const database = getDatabase(app);

// Initialisiere Firebase Storage
export const storage = getStorage(app);

// Exportiere auch die App (optional, falls du sie brauchst)
export default app;
